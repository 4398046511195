<template>
<v-form ref="form" v-model="valid" lazy-validation>
  <div>
   
    <section class="lightColor review_date">
    
      <div class="review" v-if="company">
        <v-row class="mr-5 pr-5">
          <v-col sm="2" class="text-right">
            <b-avatar
              size="120"
              variant="info"
              :src="company['company_logo']"
            ></b-avatar>
          </v-col>
          <v-col sm="10">
            <b
              ><p class="font-size-20">{{ company["company_name"] }}</p></b
            >
            <p class="font-size-14 text-muted">
              {{ company["tag_line"] }}
            </p>
            <p class="font-size-12">
              {{ company["rating"]
              }}<b-form-rating
                v-model="company['rating']"
                class="myrating pl-0"
                inline
                readonly
              ></b-form-rating>
              <span class="txt-brown cs-span"
                >{{ company["review"] }} Reviews</span
              >
            </p>
          </v-col>
        </v-row>
      </div>
    </section>
    <section class="review" v-if="company">
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col>
          <b
            ><p class="font-size-18 mt-5">
              Write a review for {{ company["company_name"] }}
            </p></b
          >
        </v-col>
      </v-row>

      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col sm="4">
          <label for="fname" class="font-size-12">Full Name</label>

          <v-text-field
            outlined
            dense
            id="fname"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Full Name"
            v-model="full_name"
            :rules="fullnameRules"
            required
             v-on:keydown="msg=false"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <label for="cname" class="font-size-12">Company Name</label>

          <v-text-field
            outlined
            dense
           
            id="cname"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Company Name"
            :value="company['company_name']"
            readonly
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <label class="font-size-12">Type Of Project</label>
          <v-select
            :items="items"
            outlined
            dense
            class="mt-2 font-size-12"
            placeholder="Select type of project"
            v-model="project_type"
              :rules="[v => !!v || 'Project Type is required']"
               v-on:change="msg=false"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col sm="12">
          <label for="ptitle" class="font-size-12">Project Title</label>

          <v-text-field
            outlined
            dense
            
            v-model="project_title"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Project Title"
             :rules="projectTitleRules"
              v-on:keydown="msg=false"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col sm="4">
          <label for="c" class="font-size-12">Cost Range</label>
          <v-select
            :items="cost_list"
            v-model="cost_range"
            outlined
            dense
            class="mt-2 p-0 font-size-12"
            placeholder="Select Cost Range"
          :rules="[v => !!v || 'Cost Range is required']"
           v-on:change="msg=false"
          ></v-select>
        </v-col>
        <v-col sm="4">
          <label class="font-size-12" for="datepicker-dateformat2"
            >Project Start</label
          >

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="m"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                append-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                class="mt-2 align-top"
                  :rules="[v => !!v || 'Start Date is required']"
                   placeholder="Start Date"
                    v-on:click="msg=false"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              class="reviewselect"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="4">
          <label class="font-size-12" for="datepicker-dateformat2"
            >Project End</label
          >
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date2"
            transition="scale-transition"
            offset-y
            min-width="auto"
            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date2"
                append-icon="mdi-calendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                class="mt-2"
                  :rules="[v => !!v || 'End Date is required']"
                    placeholder="End Date"
                     v-on:click="msg=false"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date2" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu2.save(date2)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <hr class="ml-5 mr-5" />
    </section>
    <section class="review">
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col>
          <b> <p class="font-size-16">Overall Ratings</p></b>
        <v-input :value="Orating"  :rules="[v => !!v || 'Overall Ratings is required']">  <b-form-rating v-model="Orating" class="myrating pl-0 pr-0" inline>
          </b-form-rating></v-input>
        </v-col>
      </v-row>
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col>
          <label class="font-size-12">Details</label>

          <v-text-field
            outlined
            dense
            class="mt-2 font-size-12 bg-transparent"
            v-model="Oreview"
            placeholder="Enter text"
            :rules="detailRules"
             v-on:keydown="msg=false"
           
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col sm="4">
          <p class="font-size-16">Quality</p>
          <p>
              <v-input :value="Qrating"  :rules="[v => !!v || 'Quality Ratings is required']">  <b-form-rating v-model="Qrating" class="myrating pl-0 pr-0" inline>
            </b-form-rating></v-input>
          </p>
          <label class="font-size-12">Details</label>

          <v-text-field
            outlined
            dense
            v-on:keydown="msg=false"
            class="mt-2 font-size-12 bg-transparent"
            v-model="Qreview"
            placeholder="Enter text"
             :rules="qualityDetailRules"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <p class="font-size-16">Reliability</p>
          <p>
         <v-input :value="Rrating"  :rules="[v => !!v || 'Reliability Ratings is required']">    <b-form-rating v-model="Rrating" class="myrating pl-0 pr-0" inline>
            </b-form-rating></v-input>
          </p>
          <label class="font-size-12">Details</label>

          <v-text-field
            outlined
            dense
            v-on:keydown="msg=false"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Enter text"
            v-model="Rreview"
               :rules="reliabilityDetailRules"
          ></v-text-field>
        </v-col>
        <v-col sm="4">
          <p class="font-size-16">Ability</p>
          <p>
           <v-input :value="Arating"  :rules="[v => !!v || 'Ability Ratings is required']">     <b-form-rating v-model="Arating" class="myrating pl-0 pr-0" inline>
            </b-form-rating></v-input>
          </p>
          <label class="font-size-12">Details</label>

          <v-text-field
            outlined
            dense
             v-on:keydown="msg=false"
            class="mt-2 font-size-12 bg-transparent"
            placeholder="Enter text"
            v-model="Areview"
               :rules="abilityDetailRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <hr class="ml-5 mr-5" />
    </section>
    <section class="review mb-5">
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col sm="4">
          <label class="font-size-12">Select Categories</label>
          <v-select
            :items="catregory"
            outlined
            dense
            class="select3 mt-2"
            placeholder="Select Categories"
            v-model="company_work_for"
             :rules="[v => !!v || 'Category is required']"
                v-on:change="msg=false"
          ></v-select>
        </v-col>
        <v-col sm="4"> </v-col>
        <v-col sm="4"> </v-col>
      </v-row>
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col>
          <label class="font-size-12">Tell us about your experience</label>
          <!-- <b-form-textarea
            id="textarea-small"
            size="sm"
            placeholder="Enter text"
            class="bg-transparent border-radius-8 font-size-12 mt-2"
          ></b-form-textarea> -->
          <v-textarea
            outlined
            class="bg-transparent border-radius-8 font-size-12 mt-2"
            :counter="40"
            placeholder="Enter text"
            required
            v-model="company_background"
            :rules="tellUsDetailRules"
               v-on:keydown="msg=false"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="ml-5 mr-5 pl-5 pr-5">
        <v-col>
          <b-button
            variant="outline-dark"
            class="button-custom-blck-company button-height-width font-size-14"
            @click="gotosubmit()"
            >Post Review</b-button
          >
           <div class="mt-3 alert alert-success alert-dismissible fade show" v-if="msg">
        <strong>Success!</strong> Your Review has been sent successfully.
    </div>
        </v-col>
      </v-row>
    </section>
     
  </div>
   </v-form>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      msg:false,
      valid: true,
      activePicker: null,
      date: null,
      menu: false,
      activePicker2: null,
      date2: null,
      menu2: false,
      company: null,
      rating: 3,
      Orating: 0,
      Oreview: null,
      Qrating: 0,
      Qreview: null,
      Rrating: 0,
      Rreview: null,
      Arating: 0,
      Areview: null,
      company_work_for: null,
      company_background: null,
      items: [
        "Web Development",
        "Mobile App Development",
        "E-commerce Development",
        "Game Development",
        "Web Design",
        "Digital Marketing",
        "Application Testing",
        "IOT Development",
        "UI/UX Design",
      ],
      full_name: null,
      company_name: null,
      project_type: null,
      project_title: null,
      cost_range: null,
      catregory: [],
      cost_list: [],
       fullnameRules: [
        v => !!v || 'Full Name is required',
      
      ],
      projectTitleRules:  [
        v => !!v || 'Project Title is required',
      
      ],
      detailRules:  [
        v => !!v || 'Detail is required',
      
      ],
      abilityDetailRules:[
        v => !!v || 'Ability Detail is required',
      
      ],
       reliabilityDetailRules:[
        v => !!v || 'Reliability Detail is required',
      
      ],
       qualityDetailRules:[
        v => !!v || 'Quality Detail is required',
      
      ],
      tellUsDetailRules:[
        v => !!v || 'field is required',
      
      ],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker2 = "YEAR"));
    },
  },
  created() {
    this.getdata();
  },
  name: "review",
  methods: {
    ...mapActions([
      "getReview",
      "getCompanyProfile",
      "getCompanyCategory",
      "getCostList",
    ]),

    getdata() {
      this.getCompanyProfile(this.$route.params.id).then((response) => {
        let rating = 0;
        let review = "";
        if (response.data.data.company_review.length) {
          response.data.data.company_review.forEach((res, i) => {
            review = parseInt(i) + 1;
            rating = parseInt(rating) + parseInt(res.rating);
          });
        }
        response.data.data.rating = rating
          ? rating / parseInt(response.data.data.company_review.length)
          : 0;
        response.data.data.review = review;

        this.company = response.data.data;
      });
      this.getCostList().then((response) => {
        response.data.data.forEach((value) => {
          this.cost_list.push(value.name);
        });
      });
      this.getCompanyCategory().then((response) => {
        response.data.data.forEach((value) => {
          if (value["parent_id"] == 0) {
            let s_id = value["id"];

            response.data.data.forEach((v) => {
              if (v["parent_id"] == s_id) {
                this.catregory.push(v["name"]);
              }
            });
          }
        });
      });
    },
    gotosubmit() {
      let userId = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
        if(this.$refs.form.validate())
        {

     
      this.getReview({
        user_id: userId ? userId.id : 1,
        company_id: this.company.id,
        full_name: this.full_name,
        company_name: this.company.company_name,
        project_type: this.project_type,
        project_title: this.project_title,
        cost_range: this.cost_range,
        project_start: this.date,
        project_end: this.date2,
        rating: this.Orating,
        review: this.Oreview,
        company_solution_1: this.Qrating,
        company_feedback_1: this.Qreview,
        company_solution_2: this.Rrating,
        company_feedback_2: this.Rreview,
        company_solution_3: this.Arating,
        company_feedback_3: this.Areview,
        type_company_work_for: this.company_work_for,
        company_background: this.company_background,
      }).then((res) => {
        console.log(res);
          this.$refs.form.resetValidation();
          this.full_name=null;
          this.project_type=null;
          this. project_title=null;
          this. cost_range=null;
          this. date=null;
          this. date2=null;
          this. Orating=null;
          this. Oreview=null;
          this. Qrating=null;
          this. Qreview=null;
          this. Rrating=null;
          this. Rreview=null;
           this.Arating=null;
          this. Areview=null;
          this. company_work_for=null;
           this.company_background=null;
           this.msg=true;
      });
    }  
     else {
        this.$refs.form.validate();
      } 
     }
     
    ,
  },
};
</script>
